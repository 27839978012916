import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from '@bit/azheng.joshua-tree.button';
import ScrollCTA from "../components/animations/lottie/scrollCTA"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import ReviewQueryCarousel from "../components/carousel/ReviewQueryCarousel"
import ImageMeta from "../components/ImageMeta"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

class PatientStories extends Component {
  constructor() {
    super()
    this.state = {
      didScroll: false,
      scrollPosition: "fixed",
      scrollOverflow: "hidden",
      playing: false,
    }
    this.handleFixed = this.handleFixed.bind(this)
  }

  handleFixed() {
    var that = this
    that.setState({ playing: true })
    setTimeout(() => {
      that.setState({ scrollPosition: "relative" })
      that.setState({ scrollOverflow: "unset" })
    }, 200)

    window.removeEventListener("scroll", this.handleFixed, false)
  }
  // componentWillMount(){
  //   window.scrollTo(0, 0);
  // }
  componentDidMount() {
    document.body.style.height = "103vh"
    document.body.style.overflow = "scroll !important"
    window.addEventListener("scroll", this.handleFixed)
    
  }

 

  render() {
    var otherReviews = []
    var allReviews = this.props.data.relatedOther.nodes
    for (let i = 0; i < allReviews.length; i++) {
      if (
        allReviews[i].mainProcedureDone !== "Dental Implants" &&
        allReviews[i].mainProcedureDone !== "Wisdom Teeth Removal" &&
        allReviews[i].mainProcedureDone !== "Tooth Extractions"
      ) {
        for (let j = 0; j < allReviews[i].proceduresDone.length; j++) {
          if (
            allReviews[i].proceduresDone[j].procedureDone.procedureName !==
              "Dental Implants" &&
            allReviews[i].proceduresDone[j].procedureDone.procedureName !==
              "Wisdom Teeth Removal" &&
            allReviews[i].proceduresDone[j].procedureDone.procedureName !==
              "Tooth Extractions"
          ) {
            otherReviews.push(allReviews[i])
            break;
          }
        }
      }
    }

    var staticStyle = {
      position: this.state.scrollPosition,
      overflow: this.state.scrollOverflow,
      marginTop: "88px",
      zIndex: "1",
    }

    const post = this.props.data.allUniquePagesJson.nodes[0];

    return (
      <SharedStateProvider>
        <Layout>
        <SEO title={post.metaTitle} description={post.metaDescription} />
          <div className="joshua-tree-content">
            <section className="patient-reviews-top-section">
              <div className="container">
                <div className="columns">
                  <div className="column">
                    <div
                      className="columns top-section text-section is-mobile"
                      style={{ backgroundColor: "#fff", paddingBottom: "30px"}}
                    >
                      <div className="column is-2"></div>
                      <div className="column is-20">
                        <div>
                          <h1>{post.heading}</h1>
                        </div>
                        <div dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.topBlurb)
                  )}>
                        </div>
                      </div>
                      <div className="column is-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {post.hasDentalImplants && post.hasWisdomTeeth && 
          <section className="patient-reviews-section">
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column"></div>
              </div>
            </div>
            <ReviewQueryCarousel
            carouselTypeTextTitle="Patients"
              className="dental-implants"
              id="slide-dental-implants"
              relatedReviews={this.props.data.relatedDentalImplants.nodes.concat(this.props.data.relatedWisdomTeeth.nodes)}
              carouselName="Dental Implant and Wisdom Teeth Removal"
            />
          </section>
          }
          {/* {post.hasWisdomTeeth &&
          <section className="patient-reviews-section">
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column"></div>
              </div>
            </div>

            <ReviewQueryCarousel
            carouselTypeTextTitle="Patients"
              className="wisdom-teeth"
              id="slide-wisdom-teeth"
              relatedReviews={this.props.data.relatedWisdomTeeth.nodes}
              carouselName="Wisdom Teeth"
            />
          </section>} */}
          {post.hasToothExtraction &&
          <section className="patient-reviews-section">
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column"></div>
              </div>
            </div>

            <ReviewQueryCarousel
            carouselTypeTextTitle="Patients"
              className="tooth-extraction"
              id="slide-tooth-extraction"
              relatedReviews={this.props.data.relatedExtractions.nodes}
              carouselName="Tooth Extraction"
            />
          </section>
          }
          {post.hasOther && otherReviews.length > 0 && 
          <section className="patient-reviews-section">
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column"></div>
              </div>
            </div>

            <ReviewQueryCarousel
            carouselTypeTextTitle="Reviews"
              className="other"
              id="slide-other"
              relatedReviews={otherReviews.concat(this.props.data.referringProviders.nodes)}
              carouselName="Other Recent"
            />

            
          </section>
  }
          {/* {post.hasReferringProvider &&
          <section className="patient-reviews-section" style= {{ paddingBottom: "72px"}}>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column"></div>
              </div>
            </div>

            <ReviewQueryCarousel
            carouselTypeTextTitle="Providers"
              className="referring-providers"
              id="slide-referring-providers"
              relatedReviews={this.props.data.referringProviders.nodes}
              carouselName="Our Referring"
            />

            
          </section>
        } */}
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const query = graphql`
  query reviewsQueryEs ($loneUrl: String!)  {
    allUniquePagesJson(filter: {title: {eq: $loneUrl}}) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        hasDentalImplants
        hasOther
        hasReferringProvider
        hasToothExtraction
        hasWisdomTeeth
      }
    }
    relatedDentalImplants: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: "Dental Implants" } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
    relatedWisdomTeeth: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: "Wisdom Teeth Removal" } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
                monthYear {
          month
          year
        }
      }
    }
    relatedExtractions: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: "Tooth Extractions" } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
                monthYear {
          month
          year
        }
      }
    }
    relatedOther: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
                monthYear {
          month
          year
        }
      }
    }
    referringProviders: allReviewsJson(
      filter: { reviewType: { eq: "Provider" }, reviewLanguage: { eq: "ENG" }, }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
                monthYear {
          month
          year
        }
      }
    }
  }
`

export default PatientStories