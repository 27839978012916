import React, { Component } from "react";
import Slider from "react-slick";
import {Image,Video,Transformation,CloudinaryContext,} from "cloudinary-react"
import { Link, StaticQuery, graphql, navigate } from "gatsby"
import { shuffleArray, getMonthYearFromStrings, sortArchive, sortReviewsByDate } from "../../utils/utils"
import ImageMeta from "../ImageMeta"
import NuvoImage from "../NuvoImage"

import { SamplePrevArrow, SampleNextArrow } from "./ChevronArrows"

function SingleSlide(props) {

  return(
<div>
  <Link className="carousel-image-link" to={"/" + props.title.toLowerCase()+"/"} style={{display: "block"}}>
    <NuvoImage
      useAR
      cloudName="nuvolum"
      publicId={props.thumbnailPublicId}
      
      // width="375"
      // height="600"
  >
    <Transformation crop="scale" height="419" />
  </NuvoImage>
  <div className="carousel-caption-wrapper">
    <span className="carousel-name">Meet {props.reviewerName}</span>
    <span className="carousel-time">{getMonthYearFromStrings(props.monthYear.year, props.monthYear.month)}</span>
  </div>
  </Link>
</div>
  )
}


export default class ReviewQueryCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
          relatedReviews: props.relatedReviews,
          canSlideLink: true,
          id: props.id
        }
        this.setCanSlideLink = this.setCanSlideLink.bind(this)
    }

  // Shuffle on mount, shuffling in render causes multiple shuffles
  componentDidMount() {
    let relatedReviews = this.state.relatedReviews
    relatedReviews = sortReviewsByDate(relatedReviews)
    this.setState({
      relatedReviews: relatedReviews
    })
  }

  setCanSlideLink(status) {
    if (status === true || status === false){
      this.setState({ canSlideLink : status });
    } else {
      this.setState({ canSlideLink : !this.state.canSlideLink });
    }
  }

  render() {

    const settings = {
      className: "desktop-slider-375x600 slidecount-" + this.props.relatedReviews.length,
      id: this.state.id,
      accessibility: true,
      centerMode: true,
      infinite: this.props.relatedReviews.length <= 3 ? false : true,
      centerPadding: "0px",
      swipeToSlide: true,
      slidesToShow: 5,
      focusOnSelect: false,
      draggable: false,
      speed: 500,
      nextArrow: <SampleNextArrow targetID={this.state.id} />,
      prevArrow: <SamplePrevArrow targetID={this.state.id} />,
      initialSlide: 2,
      onSwipe: () => {
        this.setCanSlideLink(true)
      },
      responsive: [
        {
          breakpoint: 1125,
          settings: {
            slidesToShow: 3,
            initialSlide: 1,
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 2,
            initialSlide: 1,
          }
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 1,
            initialSlide: 0,
          }
        }
      ]
    };

    const sliderStyle = {
      width: "auto",
      height: "auto",
      margin: "0px auto",
    }

    const sliderCopy = {
        carouselName: "Dental Implants",
        carouselTime: "December 2019",
        carouselLanguage: "ENG",
        carouselTypeTextTitle: "Patients"
      }

    const slides = this.state.relatedReviews.map((review) => {
        return(
            <SingleSlide
            key={review.title}
            title={review.title}
            reviewerName={review.reviewerName}
            thumbnailPublicId={review.thumbnailPublicId}
            monthYear={review.monthYear}
            reviewLanguage={review.reviewLanguage}
            />
        )
    })


    return (
      <div id={this.state.id}>
        <h6 className={`carousel-side-text ${this.props.className}`}>{this.props.relatedReviews.length} Stories From</h6>
        <h3 className={`carousel-side-text ${this.props.className}`}>{this.props.carouselName} {this.props.carouselTypeTextTitle}</h3>
        <Slider {...settings} style={{...sliderStyle,}} setCanSlideLink={this.setCanSlideLink}>
       {slides}
        </Slider>
      </div>
    );
  }
}
